var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "c-section c-section--mb32" },
      [
        _c("h2", { staticClass: "c-title6" }, [
          _vm._v("1. 機種変更を検討している方"),
        ]),
        _c(
          "Button",
          {
            attrs: {
              "style-type": "secondary",
              fluid: "",
              href: _vm.$customUrlScheme.page(
                "standalone",
                "url=/takeover?generate"
              ),
            },
          },
          [_vm._v(" 引継コードを発行する ")]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "c-section" },
      [
        _c("h2", { staticClass: "c-title6" }, [_vm._v("2. 機種変更をした方")]),
        _c(
          "Button",
          {
            attrs: {
              "style-type": "secondary",
              fluid: "",
              href: _vm.$customUrlScheme.page(
                "standalone",
                "url=/takeover?input"
              ),
            },
          },
          [_vm._v(" データを引き継ぐ ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "c-section c-section--mb32" }, [
      _c("p", [
        _vm._v(
          " 機種変更を検討している方は1へ、すでに機種変更をした方は2へお進みください。 "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }