<template>
  <div>
    <div class="c-section c-section--mb32">
      <p>
        機種変更を検討している方は1へ、すでに機種変更をした方は2へお進みください。
      </p>
    </div>

    <div class="c-section c-section--mb32">
      <h2 class="c-title6">1. 機種変更を検討している方</h2>
      <Button
        style-type="secondary"
        fluid
        :href="$customUrlScheme.page('standalone', 'url=/takeover?generate')">
        引継コードを発行する
      </Button>
    </div>

    <div class="c-section">
      <h2 class="c-title6">2. 機種変更をした方</h2>
      <Button
        style-type="secondary"
        fluid
        :href="$customUrlScheme.page('standalone', 'url=/takeover?input')">
        データを引き継ぐ
      </Button>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss"></style>
